import { constants as aiFirstCodingExerciseConstants } from '@datacamp/ai-first-coding-exercise-core';
import { constants as chatExerciseConstants } from '@datacamp/chat-exercise-core';
import { constants as cloudExerciseConstants } from '@datacamp/cloud-exercise-core';
import { constants as datalabExerciseConstants } from '@datacamp/datalab-exercise-core';
import { constants as gpuExerciseConstants } from '@datacamp/gpu-exercise-core';
import { constants as promptingExerciseConstants } from '@datacamp/prompting-exercise-core';
import { constants as pureMultipleChoiceExerciseConstants } from '@datacamp/pure-multiple-choice-exercise-core';
import { constants as videoExerciseConstants } from '@datacamp/video-exercise-core';
import { constants as visualExerciseConstants } from '@datacamp/visual-exercise-core';
import React from 'react';

const localExercises = {
  SpreadsheetsExercise: React.lazy(
    () =>
      import(
        /* webpackChunkName: "se" */
        '@datacamp/spreadsheets-exercise-client'
      ),
  ),
  DragAndDropExercise: React.lazy(
    () =>
      import(
        /* webpackChunkName: "dnde" */
        '@datacamp/drag-and-drop-exercise-client'
      ),
  ),
  IDEExercise: React.lazy(
    () =>
      import(
        /* webpackChunkName: "idee" */
        '@datacamp/ide-exercise-client'
      ),
  ),
  ExplorableExercise: React.lazy(
    () =>
      import(
        /* webpackChunkName: "ee" */
        '@datacamp/explorable-exercise-client'
      ),
  ),
  RemoteDesktopExercise: React.lazy(
    () =>
      import(
        /* webpackChunkName: "rde" */
        '@datacamp/remote-desktop-exercise-client'
      ),
  ),
  [videoExerciseConstants.exercises.VIDEO_EXERCISE]: React.lazy(
    () =>
      import(
        /* webpackChunkName: "ve" */
        /* webpackPreload: true */
        '@datacamp/video-exercise-client'
      ),
  ),
  [pureMultipleChoiceExerciseConstants.exercises.PURE_MULTIPLE_CHOICE_EXERCISE]:
    React.lazy(
      () =>
        import(
          /* webpackChunkName: "pmce" */
          '@datacamp/pure-multiple-choice-exercise-client'
        ),
    ),
  [chatExerciseConstants.exercises.CHAT_EXERCISE]: React.lazy(
    () =>
      import(
        /* webpackChunkName: "pmce" */
        '@datacamp/chat-exercise-client'
      ),
  ),
  [cloudExerciseConstants.exercises.CLOUD_EXERCISE]: React.lazy(
    () =>
      import(
        /* webpackChunkName: "ce" */
        '@datacamp/cloud-exercise-client'
      ),
  ),
  [gpuExerciseConstants.exercises.GPU_EXERCISE]: React.lazy(
    () =>
      import(
        /* webpackChunkName: "ge" */
        '@datacamp/gpu-exercise-client'
      ),
  ),
  [visualExerciseConstants.exercises.VISUAL_EXERCISE]: React.lazy(
    () =>
      import(
        /* webpackChunkName: "vise" */
        '@datacamp/visual-exercise-client'
      ),
  ),
  [datalabExerciseConstants.exercises.DATALAB_EXERCISE]: React.lazy(
    () =>
      import(
        /* webpackChunkName: "dle" */
        '@datacamp/datalab-exercise-client'
      ),
  ),
  [promptingExerciseConstants.exercises.PROMPTING_EXERCISE]: React.lazy(
    () =>
      import(
        /* webpackChunkName: "pe" */
        '@datacamp/prompting-exercise-client'
      ),
  ),
  [aiFirstCodingExerciseConstants.exercises.AI_FIRST_CODING_EXERCISE]:
    React.lazy(
      () =>
        import(
          /* webpackChunkName: "afce" */
          '@datacamp/ai-first-coding-exercise-client'
        ),
    ),
};

export const localExerciseTypes = Object.keys(localExercises);

// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
// eslint-disable-next-line import/no-anonymous-default-export
export default (type: any) => localExercises[type];
