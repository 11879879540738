/** @jsx jsx */
import type { Resource } from '@datacamp/exercise-contract';
import { constants as videoExerciseConstants } from '@datacamp/video-exercise-core';
import { useMediaQuery } from '@datacamp/waffles/hooks';
import { jsx } from '@emotion/react';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect } from 'react';
import { useStore } from 'react-redux';

import config from '../../config';
import prometheusClient from '../../helpers/prometheusClient';
import trackProgressGtm from '../../helpers/trackProgressGtm';
import { getLanguage } from '../../i18n';
import DCSpinner from '../DCSpinner';
import MuxSessionPreloader from '../MuxSessionPreloader/MuxSessionPreloader';
import StandaloneExercises, {
  type Props as StandaloneExercisesProps,
} from '../StandaloneExercises';
import { BaseLayout } from '../Universal';
import VideoExerciseHead from '../VideoExerciseHead/VideoExerciseHead';

const videoExerciseType = videoExerciseConstants.exercises.VIDEO_EXERCISE;

const useTrackExerciseStart = (exerciseId: number, courseId?: number) => {
  const client = prometheusClient();
  useEffect(() => {
    if (exerciseId == null) {
      return;
    }
    client.increment('le_count_exercise_start', {}, 1);
  }, [client, exerciseId, courseId]);
};

export type Props = {
  authenticationToken?: string;
  chapter: {
    description: string;
    last_updated_on: string;
    title: string;
  };
  chapterId?: number;
  courseId?: number;
  currentSubExercise?: Record<string, unknown>;
  email?: string;
  exercise: {
    assignment: string;
    externalId: number;
    id: number;
    instructions: string;
    language: string;
    pre_exercise_code?: string;
    projector_key?: string;
    sample_code?: string;
    sct?: string;
    solution?: string;
    title: string;
    type?: string;
    user?: unknown;
    video_hls?: string;
    xp?: number;
  };
  exerciseId?: number;
  exerciseProgress: {
    exercise_id?: number;
  };
  hasActiveSubscription?: boolean;
  isActive: boolean;
  isApplicationBooted: boolean;
  isLoggedIn?: boolean;
  language?: string;
  multiplexerRuntimeConfig?: string;
  multiplexerUrl?: string;
  nextIdsByExerciseType?: {
    [key: string]: number[];
  };
  onMultiplexerStatusUpdate: StandaloneExercisesProps['onMultiplexerStatusUpdated'];
  onNext: StandaloneExercisesProps['onNext'];
  onSubmitted: (args: {
    code: string;
    correct: boolean;
    exerciseType: string | undefined;
    message: string;
    xp?: number; // TODO: we don't send XP in this callback so this should be removed.
  }) => void;
  onXpStandaloneExerciseUpdate: StandaloneExercisesProps['onXpUpdated'];
  onXpUpdate: (...args: any[]) => any;
  pathLanguage: string;
  progressIndicatorVisible?: boolean;
  resources?: Resource[];
  startSession: (...args: any[]) => any;
  userId?: number;
};

const ExerciseHandler: React.FC<Props> = ({
  authenticationToken,
  chapter,
  chapterId,
  courseId,
  currentSubExercise,
  email,
  exercise,
  exerciseId,
  exerciseProgress,
  hasActiveSubscription,
  isActive,
  isApplicationBooted,
  isLoggedIn,
  language,
  multiplexerRuntimeConfig,
  multiplexerUrl,
  nextIdsByExerciseType,
  onMultiplexerStatusUpdate,
  onNext,
  onSubmitted,
  onXpStandaloneExerciseUpdate,
  onXpUpdate,
  pathLanguage,
  progressIndicatorVisible = false,
  resources,
  startSession,
  userId,
}) => {
  const store = useStore();
  useTrackExerciseStart(exercise.id, courseId);

  let Exercise: any = null;
  const onStartSession = (forceNew: any, override = {}) => {
    const sessionSettings = {
      ...exercise,
      ...currentSubExercise,
      pec: exercise.pre_exercise_code,
      force_new: forceNew,
      ...override,
    };
    startSession(sessionSettings);
  };

  let isStandaloneExercise = false;
  let showExerciseToggle = false;
  switch (exercise.type) {
    case 'NormalExercise':
    case 'NormalCodingExercise':
    case 'ShinyExercise':
    case 'MultipleChoiceExercise':
    case 'BokehServerExercise':
    case 'SingleProcessExercise':
    case 'ExamExercise':
    case 'MarkdownExercise':
    case 'TabExercise':
    case 'BulletExercise':
    case 'TabConsoleExercise':
    case 'BulletConsoleExercise':
    case 'RCppExercise':
    case 'ConsoleExercise':
      Exercise = BaseLayout;
      showExerciseToggle = true;
      break;
    default:
      if (!isEmpty(exercise.type)) {
        isStandaloneExercise = true;
      } else {
        Exercise = DCSpinner;
      }
      break;
  }

  const onSubmittedWithExType = useCallback(
    ({
      code,
      correct,
      message,
      xp,
    }: Parameters<StandaloneExercisesProps['onSubmitted']>[0] & {
      xp?: number;
    }) => {
      const oldState = store.getState().toJS();
      onSubmitted({ code, correct, message, xp, exerciseType: exercise.type });
      const newState = store.getState().toJS();
      // this fires gtm progress events for standalone exercises
      // for internal exercises see src/redux/actions/exercises.ts
      trackProgressGtm(oldState, newState);
    },
    [store, onSubmitted, exercise.type],
  );

  const { isAboveSmall } = useMediaQuery();
  const shouldShowEditorToggle = !isAboveSmall && showExerciseToggle;

  return (
    <main
      css={{
        position: 'absolute',
        top: shouldShowEditorToggle ? 100 : 54, // height of top Navbar
        bottom: progressIndicatorVisible ? 32 : 12,
        right: 12,
        left: 12,
        overflow: 'hidden',
      }}
    >
      <MuxSessionPreloader exercise={exercise} startSession={onStartSession} />
      {exercise.type === videoExerciseType ? (
        <VideoExerciseHead
          exerciseTitle={exercise.title}
          chapterTitle={chapter.title}
          chapterDescription={chapter.description}
          chapterLastUpdatedOn={chapter.last_updated_on}
          projectorKey={exercise.projector_key}
          projectorUrl={config.urls.projector}
          videoHls={exercise.video_hls}
        />
      ) : null}
      {!isStandaloneExercise && (
        <Exercise
          isApplicationBooted={isApplicationBooted}
          key={exercise.id}
          exercise={exercise}
          currentSubExercise={currentSubExercise}
          user={exercise.user || {}}
          chapter={chapter}
          exerciseProgress={exerciseProgress}
          startSession={onStartSession}
          /*  Required for external exercises */
          isActive={isActive}
          courseId={courseId}
          chapterId={chapterId}
          exerciseId={exerciseId}
          email={email}
          authenticationToken={authenticationToken}
          multiplexerUrl={multiplexerUrl}
          multiplexerRuntimeConfig={multiplexerRuntimeConfig}
          language={language}
          onMultiplexerStatusUpdate={onMultiplexerStatusUpdate}
          onXpUpdate={onXpUpdate}
          onNext={onNext}
        />
      )}
      {isApplicationBooted && (
        // @ts-expect-error ts-migrate(2769) FIXME: Type 'undefined' is not assignable to type 'boolea... Remove this comment to see the full error message
        <StandaloneExercises
          id={exercise.externalId}
          nextIdsByExerciseType={nextIdsByExerciseType}
          type={exercise.type}
          isStandaloneExercise={isStandaloneExercise}
          isActive={isActive}
          user={{
            id: userId,
            authenticationToken,
            email,
            hasActiveSubscription,
            isLoggedIn,
            language: getLanguage(pathLanguage),
          }}
          resources={resources}
          environment={config.getStandaloneExerciseEnvironment()}
          onSubmitted={onSubmittedWithExType}
          onMultiplexerStatusUpdated={onMultiplexerStatusUpdate}
          onXpUpdated={onXpStandaloneExerciseUpdate}
          onNext={onNext}
        />
      )}
    </main>
  );
};

export default ExerciseHandler;
