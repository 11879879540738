import type { IExerciseContract } from '@datacamp/exercise-contract';
import {
  Actions,
  ExerciseClient,
  exercisePropTypes,
} from '@datacamp/exercise-contract';
import type {
  IExerciseFinishedAction,
  IExerciseSubmittedAction,
  ISetExerciseStatusAction,
  ISetExerciseXpAction,
} from '@datacamp/exercise-contract/lib/Actions';
import isEqual from 'lodash/isEqual';
import isNull from 'lodash/isNull';
import keys from 'lodash/keys';
import pick from 'lodash/pick';
import type PropTypes from 'prop-types';
import { PureComponent } from 'react';

type State = {
  isReady: boolean;
};

type ExercisePropTypes = PropTypes.InferProps<typeof exercisePropTypes>;

type ChildProps = {
  isCurrent: boolean;
  isReady: boolean;
  setExerciseClient: (contentWindow: {
    postMessage: typeof postMessage;
  }) => void;
};

// submission is renamed to code
type OnSubmittedArg = Omit<IExerciseSubmittedAction, 'submission'> & {
  code: IExerciseSubmittedAction['submission'];
};
export type Props = ExercisePropTypes & {
  children: (props: ChildProps) => React.ReactNode;
  isCurrent: boolean;
  onMultiplexerStatusUpdated: (args: ISetExerciseStatusAction) => void;
  onNext: (args: IExerciseFinishedAction) => void;
  onSubmitted: (args: OnSubmittedArg) => void;
  onXpUpdated: (args: ISetExerciseXpAction) => void;
  type: string;
};

export default class ExerciseContract extends PureComponent<Props, State> {
  exerciseClient: ExerciseClient | null;

  state = {
    isReady: false,
  };

  constructor(props: Props) {
    super(props);
    this.exerciseClient = null;
  }

  componentWillReceiveProps(nextProps: Props) {
    if (isNull(this.exerciseClient)) {
      return;
    }

    if (!this.props.isCurrent && nextProps.isCurrent) {
      this.bindEventListeners();
    }

    if (this.hasNewExerciseProps(nextProps) && this.state.isReady) {
      this.setExerciseProps(nextProps);
    }

    if (!nextProps.isCurrent && this.state.isReady) {
      this.exerciseClient.removeAllListeners();
      this.setState({
        isReady: false,
      });
    }
  }

  componentWillUnmount() {
    this.removeExerciseClient();
  }

  setExerciseProps = (props: Props) => {
    if (this.exerciseClient === null) {
      return;
    }

    this.exerciseClient.setExerciseProps(this.getExerciseProps(props));
  };

  getExerciseProps = (props: Props) =>
    pick(props, keys(exercisePropTypes)) as IExerciseContract;

  hasNewExerciseProps = (nextProps: Props) =>
    !isEqual(
      this.getExerciseProps(nextProps),
      this.getExerciseProps(this.props),
    );

  bindEventListeners = () => {
    if (isNull(this.exerciseClient)) {
      return;
    }

    this.exerciseClient.removeAllListeners();

    this.exerciseClient.on(Actions.CLIENT_READY, () => {
      this.setExerciseProps(this.props);
    });

    this.exerciseClient.on(Actions.EXERCISE_READY, () => {
      this.setState({
        isReady: true,
      });
    });
    this.exerciseClient.on(Actions.SET_EXERCISE_STATUS, (...args) =>
      this.props.onMultiplexerStatusUpdated(...args),
    );
    this.exerciseClient.on(Actions.EXERCISE_FINISHED, (...args) =>
      this.props.onNext(...args),
    );
    this.exerciseClient.on(
      Actions.EXERCISE_SUBMITTED,
      ({ correct, id, message, submission }) =>
        this.props.onSubmitted({ id, correct, message, code: submission }),
    );
    this.exerciseClient.on(Actions.SET_EXERCISE_XP, (...args) =>
      this.props.onXpUpdated(...args),
    );
  };

  removeExerciseClient() {
    if (!isNull(this.exerciseClient)) {
      this.exerciseClient.unsubscribe();
      this.exerciseClient.removeAllListeners();
      this.exerciseClient = null;
    }
  }

  setExerciseClient = (contentWindow: { postMessage: typeof postMessage }) => {
    this.removeExerciseClient();
    this.exerciseClient = new ExerciseClient({
      channelName: this.props.type,
      postElement: contentWindow,
      listenElement: window,
    });
    this.bindEventListeners();
  };

  render() {
    const { children, isCurrent } = this.props;
    const { isReady } = this.state;
    return children({
      isCurrent,
      isReady,
      setExerciseClient: this.setExerciseClient,
    });
  }
}
