/** @jsx jsx */
import { Button } from '@datacamp/waffles/button';
import { Card } from '@datacamp/waffles/card';
import { Heading } from '@datacamp/waffles/heading';
import { Cross } from '@datacamp/waffles/icon';
import { Paragraph } from '@datacamp/waffles/paragraph';
import { theme } from '@datacamp/waffles/theme';
import { tokens } from '@datacamp/waffles/tokens';
import { jsx } from '@emotion/react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const arrowStyle = {
  width: 14,
  height: 14,
  backgroundColor: tokens.colors.white,
  position: 'absolute',
  top: 18,
  right: 9,
  transform: 'rotate(45deg)',
  borderTopLeftRadius: tokens.borderRadius.medium,
  zIndex: tokens.zIndex.default,
  borderTop: `${tokens.borderWidth.thin} solid ${tokens.colors.transparentGrey}`,
  borderLeft: `${tokens.borderWidth.thin} solid ${tokens.colors.transparentGrey}`,
} as const;

const closeButtonStyle = {
  position: 'absolute',
  top: 5,
  right: 5,
  color: theme.text.subtle,
  zIndex: tokens.zIndex.default + 1, // to prevent clipping with the arrow
} as const;

const cardStyle = {
  position: 'absolute',
  top: 25,
  right: -14,
  width: 284,
  boxShadow: tokens.boxShadow.xthick,
  paddingLeft: 20,
} as const;

const buttonGroupStyle = {
  display: 'flex',
  gap: tokens.spacing.small,
  justifyContent: 'flex-end',
};

export const NotesPopup = ({
  onClose,
  openCourseNotes,
}: {
  onClose: () => void;
  openCourseNotes: () => void;
}) => {
  const { t } = useTranslation();
  const getStarted = useCallback(() => {
    openCourseNotes();
    onClose();
  }, [onClose, openCourseNotes]);

  return (
    <div css={{ position: 'relative' }}>
      <div css={arrowStyle} />
      <Card disableHoverEffect css={cardStyle}>
        <Button
          aria-label={t('NotesPopup.closeButton')}
          css={closeButtonStyle}
          icon={<Cross />}
          onClick={onClose}
          variant="plain"
        />
        <Heading size="large">{t('NotesPopup.header')}</Heading>
        <Paragraph css={{ marginBottom: tokens.spacing.medium }}>
          {t('NotesPopup.datalabText')}
        </Paragraph>
        <div css={buttonGroupStyle}>
          <Button
            variant="plain"
            data-cy="dismiss-notes-popup-button"
            onClick={onClose}
          >
            {t('NotesPopup.secondaryButton')}
          </Button>
          <Button
            variant="secondary"
            data-cy="get-started-notes-popup-button"
            onClick={getStarted}
          >
            {t('NotesPopup.primaryButton')}
          </Button>
        </div>
      </Card>
    </div>
  );
};
